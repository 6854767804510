import React, { useCallback, useEffect, useState } from 'react';
// import Colors from '../../constants/colors';
import ShowcaseExplorer from '../applications/ShowcaseExplorer';
// import Doom from '../applications/Doom';
// import OregonTrail from '../applications/OregonTrail';
import ShutdownSequence from './ShutdownSequence';
// import ThisComputer from '../applications/ThisComputer';
// import Henordle from '../applications/Henordle';
import Toolbar from './Toolbar';
import DesktopShortcut, { DesktopShortcutProps } from './DesktopShortcut';
// import Scrabble from '../applications/Scrabble';
import { IconName } from '../../assets/icons';
// import Credits from '../applications/Credits';

export interface DesktopProps {}

type ExtendedWindowAppProps<T> = T & WindowAppProps;

async function getBingImage() {
    const getImageResp = await fetch(
        'https://api.unsplash.com/search/photos?query=old-technology&orientation=landscape',
        {
            headers: {
                Authorization: `Client-ID ${process.env.WDS_SOCKET_HOST}`,
            },
        }
    );
    const response = await getImageResp.json();
    const randomIndex = Math.floor(Math.random() * response.results.length);
    console.log('responseUrl', response?.results[randomIndex]?.urls?.full);
    return response?.results[randomIndex]?.urls?.full;
}
const APPLICATIONS: {
    [key in string]: {
        key: string;
        name: string;
        shortcutIcon: IconName;
        component: React.FC<ExtendedWindowAppProps<any>>;
    };
} = {
    // computer: {
    //     key: 'computer',
    //     name: 'This Computer',
    //     shortcutIcon: 'computerBig',
    //     component: ThisComputer,
    // },
    showcase: {
        key: 'showcase',
        name: 'My Portfolio',
        shortcutIcon: 'showcaseIcon',
        component: ShowcaseExplorer,
    },
    // trail: {
    //     key: 'trail',
    //     name: 'The Oregon Trail',
    //     shortcutIcon: 'trailIcon',
    //     component: OregonTrail,
    // },
    // doom: {
    //     key: 'doom',
    //     name: 'Doom',
    //     shortcutIcon: 'doomIcon',
    //     component: Doom,
    // },
    // scrabble: {
    //     key: 'scrabble',
    //     name: 'Scrabble',
    //     shortcutIcon: 'scrabbleIcon',
    //     component: Scrabble,
    // },
    // henordle: {
    //     key: 'henordle',
    //     name: 'Henordle',
    //     shortcutIcon: 'henordleIcon',
    //     component: Henordle,
    // },
    // credits: {
    //     key: 'credits',
    //     name: 'Credits',
    //     shortcutIcon: 'credits',
    //     component: Credits,
    // },
};

const Desktop: React.FC<DesktopProps> = (props) => {
    const [windows, setWindows] = useState<DesktopWindows>({});

    const [shortcuts, setShortcuts] = useState<DesktopShortcutProps[]>([]);

    const [shutdown, setShutdown] = useState(false);
    const [numShutdowns, setNumShutdowns] = useState(1);
    const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
    const [rotationAngle, setRotationAngle] = useState(0);
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        if (backgroundImageUrl) {
            const image = new Image();
            image.src = backgroundImageUrl;
            image.onload = () => setImageLoaded(true);
        }
    }, [backgroundImageUrl]);

    useEffect(() => {
        const rotateBackground = () => {
            setRotationAngle((prevAngle) => prevAngle + 1);
        };

        const rotationInterval = setInterval(rotateBackground, 100); // Adjust rotation speed here (milliseconds)

        return () => {
            clearInterval(rotationInterval);
        };
    }, []);
    useEffect(() => {
        // Fetch the image URL and store it in the state
        getBingImage().then((url) => setBackgroundImageUrl(url));
    }, []);

    useEffect(() => {
        if (shutdown === true) {
            rebootDesktop();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shutdown]);

    useEffect(() => {
        const newShortcuts: DesktopShortcutProps[] = [];
        Object.keys(APPLICATIONS).forEach((key) => {
            const app = APPLICATIONS[key];
            newShortcuts.push({
                shortcutName: app.name,
                icon: app.shortcutIcon,
                onOpen: () => {
                    addWindow(
                        app.key,
                        <app.component
                            onInteract={() => onWindowInteract(app.key)}
                            onMinimize={() => minimizeWindow(app.key)}
                            onClose={() => removeWindow(app.key)}
                            key={app.key}
                        />
                    );
                },
            });
        });

        newShortcuts.forEach((shortcut) => {
            if (shortcut.shortcutName === 'My Showcase') {
                shortcut.onOpen();
            }
        });

        setShortcuts(newShortcuts);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rebootDesktop = useCallback(() => {
        setWindows({});
    }, []);

    const removeWindow = useCallback((key: string) => {
        // Absolute hack and a half
        setTimeout(() => {
            setWindows((prevWindows) => {
                const newWindows = { ...prevWindows };
                delete newWindows[key];
                return newWindows;
            });
        }, 100);
    }, []);

    const minimizeWindow = useCallback((key: string) => {
        setWindows((prevWindows) => {
            const newWindows = { ...prevWindows };
            newWindows[key].minimized = true;
            return newWindows;
        });
    }, []);

    const getHighestZIndex = useCallback((): number => {
        let highestZIndex = 0;
        Object.keys(windows).forEach((key) => {
            const window = windows[key];
            if (window) {
                if (window.zIndex > highestZIndex)
                    highestZIndex = window.zIndex;
            }
        });
        return highestZIndex;
    }, [windows]);

    const toggleMinimize = useCallback(
        (key: string) => {
            const newWindows = { ...windows };
            const highestIndex = getHighestZIndex();
            if (
                newWindows[key].minimized ||
                newWindows[key].zIndex === highestIndex
            ) {
                newWindows[key].minimized = !newWindows[key].minimized;
            }
            newWindows[key].zIndex = getHighestZIndex() + 1;
            setWindows(newWindows);
        },
        [windows, getHighestZIndex]
    );

    const onWindowInteract = useCallback(
        (key: string) => {
            setWindows((prevWindows) => ({
                ...prevWindows,
                [key]: {
                    ...prevWindows[key],
                    zIndex: 1 + getHighestZIndex(),
                },
            }));
        },
        [setWindows, getHighestZIndex]
    );

    const startShutdown = useCallback(() => {
        setTimeout(() => {
            setShutdown(true);
            setNumShutdowns(numShutdowns + 1);
        }, 600);
    }, [numShutdowns]);

    const addWindow = useCallback(
        (key: string, element: JSX.Element) => {
            setWindows((prevState) => ({
                ...prevState,
                [key]: {
                    zIndex: getHighestZIndex() + 1,
                    minimized: false,
                    component: element,
                    name: APPLICATIONS[key].name,
                    icon: APPLICATIONS[key].shortcutIcon,
                },
            }));
        },
        [getHighestZIndex]
    );

    const styles: StyleSheetCSS = {
        desktop: {
            minHeight: '100%',
            flex: 1,
             backgroundImage: `url(${process.env.PUBLIC_URL + "/wallpaper.gif"})`,
        //   backgroundImage: `url(${process.env.PUBLIC_URL + "/loaderimg.png"})`,
          backgroundRepeat: "no-repeat, repeat",
          backgroundSize: '100% 100%',
        },
        // desktop: {
        //     minHeight: '100%',
        //     flex: 1,
        // },
        // backgroundContainer: {
        //     width: '100%',
        //     height: '100%',
        //     backgroundImage: `url(${
        //         imageLoaded
        //             ? backgroundImageUrl
        //             : process.env.PUBLIC_URL + '/loaderimg.png'
        //     })`,
        //     backgroundRepeat: 'no-repeat, repeat',
        //     backgroundSize: '100% 100%',
        //     animation: `${
        //         backgroundImageUrl ? '' : 'rotateBackground'
        //     } 0.8s linear infinite`, // Adjust animation duration as needed (60s in this example)
        // },

        shutdown: {
            minHeight: '100%',
            flex: 1,
            backgroundColor: '#1d2e2f',
        },
        shortcutContainer: {
            position: 'absolute',
        },
        shortcuts: {
            position: 'absolute',
            top: 16,
            left: 6,
        },
        minimized: {
            pointerEvents: 'none',
            opacity: 0,
        },
    };

    const keyframes = `
    @keyframes rotateBackground {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

    // Append the keyframes to the document head
    const style = document.createElement('style');
    style.innerHTML = keyframes;
    document.head.appendChild(style);
    return !shutdown ? (
        <div style={styles.desktop}>
            <div style={styles.backgroundContainer}></div>
            {/* For each window in windows, loop over and render  */}
            {Object.keys(windows).map((key) => {
                const element = windows[key].component;
                if (!element) return <div key={`win-${key}`}></div>;
                return (
                    <div
                        key={`win-${key}`}
                        style={Object.assign(
                            {},
                            { zIndex: windows[key].zIndex },
                            windows[key].minimized && styles.minimized
                        )}
                    >
                        {React.cloneElement(element, {
                            key,
                            onInteract: () => onWindowInteract(key),
                            onClose: () => removeWindow(key),
                        })}
                    </div>
                );
            })}
            <div style={styles.shortcuts}>
                {shortcuts.map((shortcut, i) => {
                    return (
                        <div
                            style={Object.assign({}, styles.shortcutContainer, {
                                top: i * 104,
                            })}
                            key={shortcut.shortcutName}
                        >
                            <DesktopShortcut
                                icon={shortcut.icon}
                                shortcutName={shortcut.shortcutName}
                                onOpen={shortcut.onOpen}
                            />
                        </div>
                    );
                })}
            </div>
            <Toolbar
                windows={windows}
                toggleMinimize={toggleMinimize}
                shutdown={startShutdown}
            />
        </div>
    ) : (
        <ShutdownSequence
            setShutdown={setShutdown}
            numShutdowns={numShutdowns}
        />
    );
};

export default Desktop;
